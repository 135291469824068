import type { AppRouter } from '@back/trpc.router'
import type { App } from 'vue'
import { host, websocketOrigin } from '@/common/utils/defaultPath'
import { createTRPCVueQueryClient } from '@falcondev-oss/trpc-vue-query'
import * as Sentry from '@sentry/vue'
import { useQueryClient } from '@tanstack/vue-query'
import { createWSClient, httpLink, splitLink, wsLink } from '@trpc/client'

export function setupTRPCPlugin(app: App<Element>) {
  app.use({
    install(appInstance) {
      try {
        const queryClient = appInstance.runWithContext(useQueryClient)
        const trpc = createTRPCVueQueryClient<AppRouter>({
          queryClient,
          trpc: {
            links: [
              splitLink({
                condition: op => op.type === 'subscription',
                true: wsLink({
                  client: createWSClient({
                    url: `${websocketOrigin}/trpc/ws`,
                  }),
                }),
                false: httpLink({ url: `${host}/trpc/http` }),
              }),
            ],
          },
        })

        appInstance.provide('trpc', trpc)
      }
      catch (e) {
        Sentry.captureException(e)
      }
    },
  })
}
