import type { ExtendedRouteConfig } from '@/router'
import { orgAssetIQAdminRoutes } from './modules/assetIQ/orgAssetIQAdmin.routes'
import { orgRequestIQAdminRoutes } from './modules/requestIQ/orgRequestIQAdmin.routes'
import { orgSettingsRoutes } from './modules/settings/organizationSettings.routes'
import { orgUsersAdminRoutes } from './modules/users/orgUsersAdmin.routes'

export const organizationAdminRoutes: ExtendedRouteConfig = {
  path: 'Organizations',
  children: [
    {
      path: '',
      name: 'AdminOrganizations',
      component: () => import('./views/Organizations.vue'),
    },
    {
      path: ':organizationId',
      component: () => import('./views/Organization.vue'),
      props: true,
      children: [
        orgSettingsRoutes,
        orgUsersAdminRoutes,
        orgAssetIQAdminRoutes,
        orgRequestIQAdminRoutes
      ],
    },
  ],
}
