import type { ExtendedRouteConfig } from '@/router'

export const orgUsersAdminRoutes: ExtendedRouteConfig = {
  path: '',
  alias: 'Users',
  component: () => import('./views/OrganizationUsers.vue'),
  props: true,
  children: [
    {
      path: 'List',
      props: true,
      name: 'AdminOrganizationUsers',
      component: () => import('./views/OrganizationUserList.vue')
    },
    {
      path: 'Invitations',
      props: true,
      children: [
        {
          path: '',
          props: true,
          name: 'AdminOrganizationUserInvitations',
          component: () => import('./views/OrganizationUserInvitationsList.vue')
        },
        {
          path: ':invitationId',
          props: true,
          name: 'AdminOrganizationUserInvitation',
          component: () => import('./views/OrganizationUserInvite.vue')
        }
      ]
    },
    {
      path: ':userId',
      props: true,
      name: 'AdminOrganizationUser',
      component: () => import('./views/OrganizationUser.vue')
    },
  ],
}
