export const posthogFeatureFlags = {
  purchaseRequests: 'purchase_requests',
  /** @deprecated ! TO BE REMOVED */
  purchaseRequestsDemo: 'purchase_requests_demo',
  sales: 'sales',
  sigmaBookmarks: 'sigma-bookmarks',
  orgUsers: 'org-users',
  orgSettings: 'org-settings',
  trpcWebsockets: 'trpc-websockets',
} as const
