import { t } from 'elysia'

/**
 * These are prefixes for FGA relations
 */
export const FGA_RELATION_PREFIXES = {
  role: 'role',
  permission: 'permission',
  endpoint: 'endpoint',
  orgType: 'org_type',
  // TODO: Rename this to entitlement, but it will require a migration
  feature: 'feature',
} as const
export const FGA_ASSIGNMENT_RULES = {
  self_assign_to_customer_only: 'self_assign_to_customer_only',
}
export type FgaAssignmentRules = typeof FGA_ASSIGNMENT_RULES[keyof typeof FGA_ASSIGNMENT_RULES]
export interface FGA_ROLE_TYPE {
  slug: string
  name: string
  description: string
  orgType: FgaOrgSlugs | 'all'
  assignmentRules?: FgaAssignmentRules[] // Can only assign to self in customer orgs
  /**
   * At least one of these features is required for inheritance to work
   * If this is empty, no features are required.
   */
  requireOneEntitlementOf: FgaEntitlementSlug[]
  inheritsIfAnyOf?: () => string[]
  inheritsIfAny?: true // All roles in the org type
  /**
   * For roles that have a different UI
   * For example, triage members have their own settings page instead of
   * assigning them directly
   */
  hidden?: boolean
}

export interface FGA_ROLES_TYPE {
  [slug: string]: FGA_ROLE_TYPE
}
export const FGA_ORG_TYPES = {
  customer: 'customer',
  admin: 'admin',
} as const
export const FGA_ORG_TYPE_RELATIONS = Object.fromEntries(Object.values(FGA_ORG_TYPES).map((orgType) => {
  const relation = makeFgaRelation(FGA_RELATION_PREFIXES.orgType, orgType)
  return [orgType, relation]
})) as unknown as { [key in keyof typeof FGA_ORG_TYPES]: `${typeof FGA_RELATION_PREFIXES.orgType}_${(typeof FGA_ORG_TYPES)[key]}` }
export type FgaOrgSlugs = typeof FGA_ORG_TYPES[keyof typeof FGA_ORG_TYPES]
export type FgaOrgRelations = `${typeof FGA_RELATION_PREFIXES.orgType}_${typeof FGA_ORG_TYPES[keyof typeof FGA_ORG_TYPES]}`

export interface FgaEntitlementWide {
  slug: string
  name: string
  description: string
  cannotBeGrantedWith?: () => string[]
  hidden?: boolean
}

interface FGA_ENTITLEMENTS_TYPE {
  [slug: string]: FgaEntitlementWide
}
/**
 * These are the features that are available to organizations.
 * They are used to determine what UI elements to show the user, including on
 * the customer's admin portal.
 *
 * @dependencies
 * - Back/src/modules/authorization/getOrgEntitlements.ts
 *   - Response schema must be updated to include new feature slugs
 *   - Update response type in GetOrgEntitlementsSchema.response[200]
 *
 * - Front/src/modules/customerAdmin/modules/organization/modules/settings/views/OrganizationProperties.vue
 *   - Uses entitlements to display enabled modules in the UI
 *   - No direct code changes needed, relies on API response
 *
 * - Front/src/common/consts/products.const.ts
 *   - Products array must be updated to include new features
 *   - Each product maps to a feature using the entitlement property
 *
 * @updateInstructions
 * When adding or modifying features:
 * 1. Add/modify the feature in FGA_ENTITLEMENTS
 * 2. Update GetOrgEntitlementsSchema response type in getOrgEntitlements.ts
 * 3. Add corresponding product entry in products.const.ts if UI representation is needed
 * 4. Update any feature flag references if applicable
 *
 * @example Adding a new feature:
 * ```typescript
 * export const FGA_ENTITLEMENTS = {
 *   new_feature: {
 *     slug: 'new_feature',
 *     name: 'New Feature',
 *     description: 'Description',
 *   }
 * }
 * ```
 */
export const FGA_ENTITLEMENTS = {
  /**
   * Asset IQ Lite
   */
  asset_iq: {
    slug: 'asset_iq',
    name: 'Asset IQ Lite',
    description: 'Cannot be granted with Asset IQ Pro',
  },
  /**
   * Asset IQ Capital Manager
   */
  asset_iq_capital_manager: {
    slug: 'asset_iq_capital_manager',
    name: 'Asset IQ Capital Manager',
    description: 'Can be granted with Asset IQ Lite or Pro. Enables the Capital Manager dashboard.',
  },
  /**
   * Asset IQ Recalls
   * @todo: This is not yet implemented
   */
  asset_iq_recalls: {
    slug: 'asset_iq_recalls',
    name: 'Asset IQ Recalls',
    description: 'To Do',
  },
  /**
   * Asset IQ Infosec
   * @todo: This is not yet implemented
   */
  asset_iq_infosec: {
    slug: 'asset_iq_infosec',
    name: 'Asset IQ Infosec',
    description: 'To Do',
  },
  /**
   * Asset IQ Pro
   */
  asset_iq_pro: {
    slug: 'asset_iq_pro',
    name: 'Asset IQ Pro',
    description: 'Cannot be granted with Asset IQ Lite',
    cannotBeGrantedWith: (): string[] => [FGA_ENTITLEMENTS.asset_iq.slug],
  },
  /**
   * Asset IQ Pro Dashboard Access Management
   * @deprecated: This should not be used. This was created for development purposes only.
   */
  asset_iq_pro_dashboard_access_management: {
    slug: 'asset_iq_pro_dashboard_access_management',
    name: 'Asset IQ Dashboard Access Management',
    description: '',
  },
  /**
   * Request IQ
   */
  request_iq: {
    slug: 'request_iq',
    name: 'Request IQ',
    description: 'To Do',
  },
  /**
   * Request IQ Demo
   * @deprecated: This should not be used. This was created for demonstration purposes only.
   */
  request_iq_demo: {
    slug: 'request_iq_demo',
    name: 'Request IQ Demo',
    description: 'To Do',
  },
  disabled_organization: {
    slug: 'disabled_organization',
    name: 'Disabled Organization',
    description: 'This entitlement disables the organization',
    hidden: true
  }
} as const satisfies FGA_ENTITLEMENTS_TYPE
export const FGA_ENTITLEMENT_RELATIONS = Object.fromEntries(Object.values(FGA_ENTITLEMENTS).map((feature) => {
  const relation = makeFgaRelation(FGA_RELATION_PREFIXES.feature, feature.slug)
  return [feature.slug, relation]
})) as unknown as { [key in keyof typeof FGA_ENTITLEMENTS]: `${typeof FGA_RELATION_PREFIXES.feature}_${(typeof FGA_ENTITLEMENTS)[key]['slug']}` }
export type FgaEntitlementSlugs = typeof FGA_ENTITLEMENTS[keyof typeof FGA_ENTITLEMENTS]['slug']
export type FgaEntitlementRelations = `${typeof FGA_RELATION_PREFIXES.feature}_${typeof FGA_ENTITLEMENTS[keyof typeof FGA_ENTITLEMENTS]['slug']}`

export const FGA_ROLES = {
  user: {
    slug: 'user',
    name: 'User',
    description: 'Basic user role',
    orgType: 'all',
    requireOneEntitlementOf: [],
    inheritsIfAny: true,
  },
  developer: {
    slug: 'developer',
    name: 'Developer',
    description: 'Symplsoft Role for developers with extended permissions',
    orgType: 'all',
    assignmentRules: [FGA_ASSIGNMENT_RULES.self_assign_to_customer_only],
    requireOneEntitlementOf: [],
  },
  sales: {
    slug: 'sales',
    name: 'Sales',
    description: 'Symplsoft Role for sales team members',
    orgType: FGA_ORG_TYPES.admin,
    requireOneEntitlementOf: [],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.admin.slug,
    ],
  },
  admin: {
    slug: 'admin',
    name: 'Administrator',
    description: 'Symplsoft Role for Administrative Access',
    orgType: FGA_ORG_TYPES.admin,
    requireOneEntitlementOf: [],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.developer.slug,
    ],
  },
  org_owner: {
    slug: 'org_owner',
    name: 'Organization Owner',
    description: 'Allows the user to manage the entire account for the organization. This includes billing, adding administrators, and managing users.',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.admin.slug,
    ],
  },
  org_admin: {
    slug: 'org_admin',
    name: 'Organization Administrator',
    description: 'Allows the user to add other users to the organization and manage their permissions.',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_owner.slug,
    ],
  },
  // TODO: Change this to asset_iq_publisher
  asset_data_publisher: {
    slug: 'asset_data_publisher',
    name: 'Asset Data Publisher',
    description: 'Allows User to Upload and Manage Stored Asset Data',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.asset_iq.slug, FGA_ENTITLEMENTS.asset_iq_pro.slug] as const,
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
      FGA_ROLES.asset_data_admin.slug,
    ]
  },
  // TODO: Change this to asset_iq_viewer
  asset_data_viewer: {
    slug: 'asset_data_viewer',
    name: 'Asset Data Viewer',
    description: 'Allows User to View Dashboards',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.asset_iq.slug, FGA_ENTITLEMENTS.asset_iq_pro.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
      FGA_ROLES.asset_data_admin.slug,
    ]
  },
  // TODO: Change this to asset_iq_pro_admin
  asset_data_admin: {
    slug: 'asset_data_admin',
    name: 'assetIQ Pro Admin',
    description: 'Allows User to Manage assetIQ Pro',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.asset_iq_pro.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  // TODO: Change this to request_iq_submitter
  purchase_request_submitter: {
    slug: 'purchase_request_submitter',
    name: 'Purchase Request Submitter',
    description: 'Role for submitting purchase requests',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.request_iq.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  // TODO: Change this to request_iq_approver
  purchase_request_approver: {
    slug: 'purchase_request_approver',
    name: 'Purchase Request Approver',
    description: 'Role for approving purchase requests',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.request_iq.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  // TODO: Change this to request_iq_admin
  purchase_request_admin: {
    slug: 'purchase_request_admin',
    name: 'Purchase Request Admin',
    description: 'Role for approving purchase requests',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.request_iq.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  // This is a hidden role, it is used to check if a user is in the triage team
  triage_member: {
    slug: 'triage_member',
    name: 'Purchase Request Triage Member',
    description: 'Role for em purchase requests',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.request_iq.slug],
    inheritsIfAnyOf: (): string[] => [
      // we explicitly do not want to inherit from org_admin
      // that way we can have a separate role for triage members
    ],
    hidden: true
  },
  /** @deprecated ! TO BE REMOVED */
  demo_request_submitter: {
    slug: 'demo_request_submitter',
    name: 'Demo Request Submitter',
    description: 'Role for submitting demo requests',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.request_iq_demo.slug],
  },
  /** @deprecated ! TO BE REMOVED */
  demo_request_approver: {
    slug: 'demo_request_approver',
    name: 'Demo Request Approver',
    description: 'Role for approving demo requests',
    orgType: FGA_ORG_TYPES.customer,
    requireOneEntitlementOf: [FGA_ENTITLEMENTS.request_iq_demo.slug],
  },
} as const satisfies FGA_ROLES_TYPE
export const FGA_ROLE_RELATIONS = Object.fromEntries(Object.values(FGA_ROLES).map((role) => {
  const relation = makeFgaRelation(FGA_RELATION_PREFIXES.role, role.slug)
  return [role.slug, relation]
})) as unknown as { [roleSlug in keyof typeof FGA_ROLES]: `role_${(typeof FGA_ROLES)[roleSlug]['slug']}` }
export type FgaRoleRelations = `role_${typeof FGA_ROLES[keyof typeof FGA_ROLES]['slug']}`

export const fgaRoleSchema = t.Union(Object.values(FGA_ROLES).map(role => t.Literal(role.slug)))
const allRoles = Object.values(FGA_ROLES)
export const FGA_ASSIGNABLE_ROLES: { [roleSlug: string]: FgaRole[] } = {
  [FGA_ROLES.developer.slug]: allRoles,
  [FGA_ROLES.admin.slug]: allRoles.filter(role => role.slug !== FGA_ROLES.developer.slug && role.slug !== FGA_ROLES.admin.slug),
  [FGA_ROLES.org_owner.slug]: allRoles.filter(role => (role.orgType === 'all' || role.orgType === FGA_ORG_TYPES.customer) && role.slug !== FGA_ROLES.org_owner.slug),
  [FGA_ROLES.org_admin.slug]: allRoles.filter(role => (role.orgType === 'all' || role.orgType === FGA_ORG_TYPES.customer) && role.slug !== FGA_ROLES.org_admin.slug && role.slug !== FGA_ROLES.org_owner.slug),
}
export type FgaRole = typeof FGA_ROLES[keyof typeof FGA_ROLES]
export type FgaRoleSlug = FgaRole['slug']

/**
 * Admin Orgs can assign all features
 */
export const FGA_ASSIGNABLE_ENTITLEMENTS: { [roleSlug: string]: FgaEntitlementWide[] } = {
  [FGA_ROLES.admin.slug]: Object.values(FGA_ENTITLEMENTS),
  [FGA_ROLES.developer.slug]: Object.values(FGA_ENTITLEMENTS),
}
export type FgaEntitlement = typeof FGA_ENTITLEMENTS[keyof typeof FGA_ENTITLEMENTS]
export type FgaEntitlementSlug = FgaEntitlement['slug']

export const FGA_TYPES = {
  user: 'user',
  organization: 'organization',
  feature_holder: 'feature_holder',
  dashboard: 'dashboard',
  dashboard_group: 'dashboard_group',
  request_group: 'request_group',
  request: 'request',
} as const
export type FgaResourceType = typeof FGA_TYPES[keyof typeof FGA_TYPES]
/**
 * These are for constant relations in FGA
 * The rest are dynamic relations, use the makeFgaRelation function to create them.
 */
export const FGA_RELATIONS = {
  has_feature: 'has_feature',
  has_organization: 'has_organization',
  has_user: 'has_user',
  has_group: 'has_group',
  has_request_group: `has_${FGA_TYPES.request_group}`,
  computed_membership: 'computed_membership',
  computed_viewer: 'computed_viewer',
  computed_access: 'computed_access',
  computed_organization: 'computed_organization',
  computed_request_group_has_members: `computed_${FGA_TYPES.request_group}_has_members`
} as const

/**
 * These are the keys for policies in warrants in FGA
 */
export const FGA_POLICY_KEYS = {
  workOsOrganizationId: 'workOsOrganizationId',
} as const

export function makeFgaRelation<TType extends typeof FGA_RELATION_PREFIXES[keyof typeof FGA_RELATION_PREFIXES], TName extends string>(relationType: TType, relationName: TName): `${TType}_${TName}` {
  // Relation Names must be alphanumeric, underscores, and dashes only.
  return `${relationType}_${relationName}`.replace(/\//g, '-').replace(/[^\w-]/g, '_') as `${TType}_${TName}`
}
