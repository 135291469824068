import type { ExtendedRouteConfig } from '@/router'
import { FGA_ROLES } from '@back/plugins/fga/permissionSecurity.enums'
import { RouterView } from 'vue-router'

export const requestRoutes: ExtendedRouteConfig = {
  path: 'requests',
  components: {
    drawer: () => import('@/modules/dewey/components/DrawerNavigation.vue'),
    nav: () => import('@/layouts/components/NavigationBar.vue'),
    default: RouterView,
  },
  meta: {
    layout: 'NavDrawerLayout',
    authorization: {
      value: [
        FGA_ROLES.sales.slug,
        FGA_ROLES.demo_request_submitter.slug,
      ]
    },
    featureFlags: ['purchase_requests_demo']
  },
  children: [
    {
      path: '',
      name: 'ListRequestsDemo',
      component: () => import('./views/ListRequestsDemo.vue')
    },
    {
      path: 'new',
      name: 'NewDraftRequestDemo',
      component: () => import('./views/DraftRequestDemo.vue')
    },
    {
      path: 'draft/:id',
      name: 'EditDraftRequestDemo',
      component: () => import('./views/DraftRequestDemo.vue'),
      props: true
    }
  ]
}
