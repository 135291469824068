import type { ExtendedRouteConfig } from '@/router'
import { FGA_ROLES } from '@back/plugins/fga/permissionSecurity.enums'
import { posthogFeatureFlags } from '@common/consts/posthogFeatureFlagsConst'

export const bowmanRoutes: ExtendedRouteConfig = {
  path: 'budgetIQ',
  meta: {
    layoutModifier: 'fill-screen',
    authorization: {
      value: [
        FGA_ROLES.admin.slug,
        FGA_ROLES.developer.slug,
      ],
    },
    featureFlags: [
      posthogFeatureFlags.trpcWebsockets,
    ],
  },
  children: [
    {
      path: '',
      name: 'Bowman',
      component: () => import('./views/jobs/BowmanJobs.vue'),
    },
    {
      path: ':jobId',
      props: true,
      name: 'BowmanBudgetFiles',
      component: () => import('./views/files/BowmanBudgetFiles.vue'),
    }
  ]
}
