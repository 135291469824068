import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { defineStore } from 'pinia'

export const useLastRouteStore = defineStore('lastRoute', {
  state: () => ({
    lastRoute: null as RouteLocationNormalized | null,
    bookmarkedRoute: undefined as RouteLocationRaw | undefined,
  }),
  actions: {
    setLastRoute(route: RouteLocationNormalized) {
      this.lastRoute = route
    },
    setBookmarkedRoute(route: RouteLocationRaw) {
      this.bookmarkedRoute = route
    },
    clearBookmarkedRoute() {
      this.bookmarkedRoute = undefined
    },
  },
})
