import type { ExtendedRouteConfig } from '@/router'
import { FGA_ROLES } from '@back/plugins/fga/permissionSecurity.enums'

export const decaturRoutes: ExtendedRouteConfig = {
  path: 'DataRefinement',
  meta: {
    layoutModifier: 'fill-screen',
    authorization: {
      value: [
        FGA_ROLES.admin.slug,
        FGA_ROLES.developer.slug,
        FGA_ROLES.sales.slug,
      ],
    },
  },
  children: [
    {
      path: '',
      name: 'Decatur',
      component: () => import('./views/Decatur.vue'),
    }
  ]
}
