import type { FgaRole } from '@back/plugins/fga/permissionSecurity.enums'
import type { Prettify } from '@common/types/prettify.type'
import type { RouteLocationNormalizedGeneric, RouteLocationResolvedGeneric, RouteRecordNormalized, RouteRecordRaw } from 'vue-router'
import { featureStore } from '@/common/composables/useFeatureFlag'
import { useTreaty } from '@/common/composables/useTreaty'
import { host } from '@/common/utils/defaultPath'
import { adminRoutes } from '@/modules/admin/admin.routes'
import { authenticationRoutes } from '@/modules/authentication/authentication.routes'
import { userRoutes } from '@/modules/user/user.routes'
import { compactObject } from '@common/utils/compactObject'
import * as Sentry from '@sentry/vue'
import { nextTick } from 'vue'
import {
  createRouter,
  createWebHistory,
  RouterView
} from 'vue-router'
import { eventBus } from './common/event-bus/interruptBus'
import { assetIQRoutes } from './modules/assetIQ/assetIQ.routes'
import { customerAdminRoutes } from './modules/customerAdmin/customerAdmin.routes'
import { demoRoutes } from './modules/demos/demos.routes'
import { developerRoutes } from './modules/developer/developer.routes'
import { requestIQRoutes } from './modules/dewey/requestIQ.routes'
import { posthogInstance } from './plugins/posthog.plugin'
import { flagPreventsAccess } from './services/featureFlagWarnings'
import { useLastRouteStore } from './stores/lastRoute.store'
import { useSnackbarNotificationStore } from './stores/snackbarNotification.store'
import { useUserStore } from './stores/userStore'

type LogicalOperator = 'AND' | 'OR'

interface RoleAuthorization {
  value: FgaRole['slug'] | FgaRole['slug'][]
}

interface CompositeAuthorization {
  operator: LogicalOperator
  conditions: AuthorizationExpression[]
}

type AuthorizationExpression = RoleAuthorization | CompositeAuthorization

type _ExtendedRouteConfig = RouteRecordRaw & {
  meta?: {
    allowAnonymous?: boolean
    authorization?: AuthorizationExpression
    featureFlags?: string[]
    breadcrumb?: {
      name: string
      id?: string
      prependIcon?: string
      appendIcon?: string
    }
    // This is a mechanism to protect routes from being accessed by unauthorized users
    // userAccountPermissions?: RouteUserAccountPermissions
    pageName?: string
    layout?: 'AppBarDrawerLayout' | 'AppBarLayout' | 'FallbackLayout' | 'VerifyLayout' | 'NavDrawerLayout'
    layoutModifier?: 'fill-screen' | 'fill-height'
    /**
     * If true, Essentially no layout will be applied to the route
     * This makes sure that no improper API requests are made until
     * the user is authenticated by the backend
     */
    tokenRedemption?: boolean
  }
  children?: _ExtendedRouteConfig[]
}
export type ExtendedRouteConfig = Prettify<_ExtendedRouteConfig>

const systemRoutes = ['OrganizationDisabled', 'UserDisabled', 'Unavailable', 'NotAuthorized', 'NotFound', 'NotSetup']

const routes = [
  {
    path: '/',
    // components: {
    // nav: () => import('@/layouts/components/NavigationBar.vue'),
    // default: RouterView,
    // },
    children: [
      {
        path: 'ROI',
        name: 'ROIDashboard',
        meta: {
          layoutModifier: 'fill-screen',
          allowAnonymous: true,
          layout: 'AppBarLayout'
        },
        components: {
          nav: () => import('@/layouts/components/NavigationBar.vue'),
          default: () => import('@/modules/demos/views/RoiDash.vue')
        },
      },
      ...([
        {
          path: '',
          meta: {
            allowAnonymous: true,
            layout: 'VerifyLayout',
          },
          name: 'Redirect',
          props: route => ({
            switchingOrganization: route.query.switchingOrganization,
          }),
          // This route is used to redirect the user to the best route after login
          component: () =>
            import('@/views/Redirect.vue'),
        },
        authenticationRoutes,
        userRoutes,
        // dataCleanerRoutes,
        // requestRoutes,
        // financeRoutes,
        developerRoutes,
      ] as _ExtendedRouteConfig[]).map((route) => {
        const component = route.component ? route.component : RouterView
        return Object.assign({}, route, {
          component: undefined,
          components: {
            nav: () => import('@/layouts/components/NavigationBar.vue'),
            default: component,
          },
        })
      }),
      requestIQRoutes,
      assetIQRoutes,
      demoRoutes,
      adminRoutes,
      customerAdminRoutes,
    ],
  },
  // TODO: It would be better to not redirect the user to an unauthorized path but to swap out the component
  {
    path: '/Unauthorized',
    name: 'NotAuthorized',
    meta: {
      layout: 'AppBarLayout',
    },
    components: {
      nav: () => import('@/layouts/components/NavigationBar.vue'),
      default: () => import('@/views/NotAuthorized.vue'),
    },
  },
  {
    path: '/Unavailable',
    name: 'Unavailable',
    meta: {
      layout: 'AppBarLayout',
    },
    components: {
      nav: () => import('@/layouts/components/NavigationBar.vue'),
      default: () => import('@/views/Unavailable.vue'),
    },
  },
  {
    path: '/FailedToNavigate',
    name: 'FailedToNavigate',
    meta: {
      layout: 'AppBarLayout',
    },
    components: {
      default: () => import('@/views/FailedToNavigate.vue'),
      nav: () => import('@/layouts/components/NavigationBar.vue'),
    },
  },
  {
    path: '/NotSetup',
    name: 'NotSetup',
    meta: {
      layout: 'AppBarLayout',
      // allowAnonymous: true,
    },
    components: {
      default: () => import('@/views/NotSetup.vue'),
      nav: () => import('@/layouts/components/NavigationBar.vue'),
    }
  },
  {
    path: '/OrganizationDisabled',
    name: 'OrganizationDisabled',
    meta: {
      layout: 'VerifyLayout',
    },
    component: () => import('@/views/OrganizationDisabled.vue')
  },
  {
    path: '/UserDisabled',
    name: 'UserDisabled',
    meta: {
      layout: 'VerifyLayout',
    },
    component: () => import('@/views/UserDisabled.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      layout: 'AppBarLayout',
    },
    components: {
      nav: () => import('@/layouts/components/NavigationBar.vue'),
      default: () => import('@/views/NotFound.vue'),
    },
  },
] as ExtendedRouteConfig[]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const MAX_NAVIGATION_RETRIES = 3
const NAVIGATION_RETRY_KEY = 'navigation_retry_count'

class NavigationRetryManager {
  static getRetryCount(path: string): number {
    const retryData = localStorage.getItem(NAVIGATION_RETRY_KEY)
    if (!retryData)
      return 0

    try {
      const data = JSON.parse(retryData)
      return data[path] || 0
    }
    catch {
      return 0
    }
  }

  static incrementRetryCount(path: string): number {
    const retryData = localStorage.getItem(NAVIGATION_RETRY_KEY)
    let data: Record<string, number> = {}

    try {
      if (retryData) {
        data = JSON.parse(retryData)
      }
    }
    catch {
      // If parsing fails, we'll start fresh
    }

    const currentCount = (data[path] || 0) + 1
    data[path] = currentCount
    localStorage.setItem(NAVIGATION_RETRY_KEY, JSON.stringify(data))
    return currentCount
  }

  static clearRetryCount(path: string): void {
    const retryData = localStorage.getItem(NAVIGATION_RETRY_KEY)
    if (!retryData)
      return

    try {
      const data = JSON.parse(retryData)
      delete data[path]
      localStorage.setItem(NAVIGATION_RETRY_KEY, JSON.stringify(data))
    }
    catch {
      // If parsing fails, just remove the entire retry data
      localStorage.removeItem(NAVIGATION_RETRY_KEY)
    }
  }
}

export async function redirectToLogin(to?: ExtendedRouteLocationNormalized | string | null) {
  let redirectUri = typeof to === 'string' ? to : to?.fullPath
  // Don't redirect a user to 'NotAuthorized' or 'Unavailable' or 'NotFound' or 'NotSetup'
  const notRedirectableRoutes = ['NotAuthorized', 'Unavailable', 'NotFound', 'NotSetup']
  if (typeof to === 'object' && to?.name && typeof to.name === 'string' && notRedirectableRoutes.includes(to.name)) {
    redirectUri = undefined
  }
  else if (typeof to === 'string') {
    const matchedRoute = router.resolve(to)
    if (matchedRoute.name && typeof matchedRoute.name === 'string' && notRedirectableRoutes.includes(matchedRoute.name))
      redirectUri = undefined
  }
  const { data, error } = await useTreaty().authentication.authenticationUrl.get({
    query: compactObject({
      callbackUri: `${host}/auth/callback`,
      redirectUri,
    }),
  })

  // TODO: Handle error
  if (error) {
    const id = Sentry.captureException(error)
    Sentry.showReportDialog({
      eventId: id
    })
    return
  }

  // Redirect browser to the login url
  window.location.replace(data)
}

export type ExtendedRouteLocationNormalized = Prettify<Omit<RouteLocationResolvedGeneric | RouteLocationNormalizedGeneric, 'matched'> & {
  matched: Prettify<RouteRecordNormalized & {
    meta: ExtendedRouteConfig['meta']
  }>[]
  meta: ExtendedRouteConfig['meta']
}>

export function isAuthorizedToGoToRoute(to: ExtendedRouteLocationNormalized, authStore: ReturnType<typeof useUserStore>) {
  // Helper function to evaluate the new authorization expressions recursively
  function evaluateAuthorizationExpression(expression: AuthorizationExpression): boolean {
    if ('operator' in expression) {
      // CompositeAuthorization
      if (expression.operator === 'AND') {
        return expression.conditions.every(evaluateAuthorizationExpression)
      }
      else if (expression.operator === 'OR') {
        return expression.conditions.some(evaluateAuthorizationExpression)
      }
    }
    else {
      return authStore.hasRole(expression.value)
    }
    return false
  }

  // Evaluate new authorization strategy for each matched route
  const isAuthorizedNewStrategy = to.matched.every((route) => {
    const authorization = route.meta.authorization
    if (authorization)
      return evaluateAuthorizationExpression(authorization)
    return true // If no new authorization is defined, assume authorized
  })

  return isAuthorizedNewStrategy
}

router.beforeEach(async (to: ExtendedRouteLocationNormalized, from: ExtendedRouteLocationNormalized) => {
  let allowAnonymous = false
  const features = featureStore()
  for (const record of to.matched) {
    if (record.meta && typeof record.meta.allowAnonymous === 'boolean') {
      allowAnonymous = record.meta.allowAnonymous
    }
    if (allowAnonymous)
      break
  }

  // If route is anonymous, allow navigation
  if (allowAnonymous) {
    return true
  }

  // Check Feature Flags
  let blockedByFlags = false
  const featureFlags = to.matched.reduce((acc, route) => {
    if (route.meta?.featureFlags)
      return acc.concat(route.meta.featureFlags)

    return acc
  }, [] as string[])
  for (const flag of featureFlags) {
    if (!features.check(flag))
      blockedByFlags = true

    if (blockedByFlags)
      break
  }

  // Check authentication
  const userStore = useUserStore()

  if (blockedByFlags) {
    flagPreventsAccess(featureFlags as any, [...userStore.roles])
    console.warn('User is blocked from accessing', to.fullPath, 'by feature flag', featureFlags.join(', '))
    Sentry.captureMessage(`User is blocked from accessing ${to.fullPath} by feature flag ${featureFlags.join(', ')}`)
    return { name: 'NotAuthorized' }
  }

  // Initialize the user store if not already initialized
  const isAuthenticated = await userStore.initializeStore()

  if (!isAuthenticated) {
    // If not authenticated and not anonymous, redirect to login
    await redirectToLogin(to)
    return false
  }

  // Check if user or organization is disabled
  if (userStore.user?.disabled) {
    return { name: 'UserDisabled' }
  }

  if (userStore.organization?.disabled) {
    // Don't redirect if we're already on a system route
    if (to.name && typeof to.name === 'string' && systemRoutes.includes(to.name)) {
      return true
    }
    return { name: 'OrganizationDisabled' }
  }

  // Authorization checks
  if (!isAuthorizedToGoToRoute(to, userStore)) {
    return { name: 'NotAuthorized' }
  }

  return true
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    // Clear retry count on successful navigation
    NavigationRetryManager.clearRetryCount(to.fullPath)
    // Update last route store
    useLastRouteStore().setLastRoute(from)

    // Start a background authentication check after successful navigation
    // Only if the route requires authentication
    if (!to.matched.some(record => record.meta?.allowAnonymous)) {
      const userStore = useUserStore()
      userStore.startBackgroundAuthCheck()
    }

    nextTick(() => {
      posthogInstance?.capture('$pageview', {
        path: to.fullPath,
        from: from.fullPath,
      })
    })
  }
})

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    const retryCount = NavigationRetryManager.incrementRetryCount(to.fullPath)

    if (retryCount > MAX_NAVIGATION_RETRIES) {
      // Clear retry count and redirect to FailedToNavigate
      NavigationRetryManager.clearRetryCount(to.fullPath)
      router.push({
        name: 'FailedToNavigate',
        query: {
          path: to.fullPath,
          error: 'Failed to load required module after multiple attempts'
        }
      })
    }
    else {
      window.location.assign(to.fullPath)
    }
  }
})

// When a Deauthentication event is received, redirect to login
// eventBus.on('authFailure', () => {
//   router.push({ name: 'Login' })
// })

// When the user is First Seen, redirect to First Seen
// eventBus.on('firstSeen', () => {
//   if (router.currentRoute.value.name !== 'FirstSeen')
//     router.push({ name: 'FirstSeen' })
// })

// TODO: Do we use this anymore?
// When session expires, redirect to login
/*
 * @deprecated This is now handled by the authStateManager
 */
eventBus.on('sessionExpired', () => {
  // TODO: Redirect to login
  // router.push({ name: 'Login' })
  useSnackbarNotificationStore().notify('Your session has expired. Please log in again.')
})

eventBus.on('databaseDisconnected', () => {
  router.push({ name: 'Unavailable' })
  useSnackbarNotificationStore().notify('The database is currently unavailable. Please try again later.')
})

eventBus.on('organizationDisabled', () => {
  router.push({ name: 'OrganizationDisabled' })
})

eventBus.on('userDisabled', () => {
  router.push({ name: 'UserDisabled' })
})

eventBus.on('redirectToLogin', (path) => {
  redirectToLogin(path)
})

export default router
