/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Composables
import { eventBus } from '@/common/event-bus/interruptBus'
// Plugins
import { registerPlugins } from '@/plugins'

import { createApp } from 'vue'

// Components
import App from './App.vue'

const app = createApp(App)

registerPlugins(app)

app.provide('eventBus', eventBus)
app.mount('#app')

export { app }
